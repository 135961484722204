import { AppUserInformation } from "@/models/app/user-information";
import { AppCoupon } from "@/models/app/coupon";
import { AppOrder } from "@/models/app/order";

export interface AppUser {
  id: number | null;
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  role: string;
  is_consent: boolean;
  is_verified: boolean;
  created_at: string;
  updated_at: string;
  user_information: AppUserInformation;
  coupons: AppCoupon[];
  orders: AppOrder[];
}

export class AppUser implements AppUser {
  public id: number | null;
  public name: string;
  public email: string;
  public password: string;
  public password_confirmation: string;
  public role: string;
  public is_consent: boolean;
  public is_verified: boolean;
  public created_at: string;
  public updated_at: string;
  public user_information: AppUserInformation;
  public coupons: AppCoupon[];
  public orders: AppOrder[];

  public constructor() {
    this.id = null;
    this.name = "";
    this.email = "";
    this.password = "";
    this.password_confirmation = "";
    this.role = "";
    this.is_consent = false;
    this.is_verified = false;
    this.created_at = "";
    this.updated_at = "";
    this.user_information = new AppUserInformation();
    this.coupons = [];
    this.orders = [];
  }
}
