// import { AppUser } from "@/models/app/user";

export interface AppUserInformation {
  id: number | null;
  user_id: number | null;
  information: {
    billing_information: {
      name: string;
      address: string;
      city: string;
      province: string;
      country: string;
      postal_code: string;
      phone: string;
      email: string;
    };
    delivery_information: {
      name: string;
      address: string;
      city: string;
      province: string;
      country: string;
      postal_code: string;
      phone: string;
      email: string;
    };
    currency: number | null;
  };
  created_at: string;
  updated_at: string;
  // user: AppUser;
}

export class AppUserInformation implements AppUserInformation {
  public id: number | null;
  public user_id: number | null;
  public information: {
    billing_information: {
      name: string;
      address: string;
      city: string;
      province: string;
      country: string;
      postal_code: string;
      phone: string;
      email: string;
    };
    delivery_information: {
      name: string;
      address: string;
      city: string;
      province: string;
      country: string;
      postal_code: string;
      phone: string;
      email: string;
    };
    currency: number | null;
  };
  public created_at: string;
  public updated_at: string;
  // public user: AppUser;

  public constructor() {
    this.id = null;
    this.user_id = null;
    this.information = {
      billing_information: {
        name: "",
        address: "",
        city: "",
        province: "",
        country: "",
        postal_code: "",
        phone: "",
        email: "",
      },
      delivery_information: {
        name: "",
        address: "",
        city: "",
        province: "",
        country: "",
        postal_code: "",
        phone: "",
        email: "",
      },
      currency: null,
    };
    this.created_at = "";
    this.updated_at = "";
    // this.user = new AppUser();
  }
}
