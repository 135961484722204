import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__content" }
const _hoisted_3 = { class: "header__content-action" }
const _hoisted_4 = { class: "action" }
const _hoisted_5 = { class: "action__user" }
const _hoisted_6 = { class: "action__menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_User = _resolveComponent("User")!
  const _component_el_container = _resolveComponent("el-container")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "header__content-logo" }, "loli.stream", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, " Hi, " + _toDisplayString(_ctx.user.name), 1),
              _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("a", {
                  href: "#",
                  class: "action__menu-icon",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isActionMenuBoxUserVisible = true), ["prevent"]))
                }, [
                  _createVNode(_component_User)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["action__menu-box", { 'action__menu-box--is-visible': _ctx.isActionMenuBoxUserVisible }])
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "action__menu-box-title" }, "Menu", -1)),
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", null, [
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
                      }, "Settings")
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setLogout && _ctx.setLogout(...args)), ["prevent"]))
                      }, "Logout")
                    ])
                  ])
                ], 2)
              ])), [
                [_directive_click_outside, _ctx.setIsActionMenuBoxUserVisible]
              ])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}